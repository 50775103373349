<template>
    <b-card no-body>
      <div class="card-header">
        <!-- Title & SubTitle -->
        <div>
          <b-card-title
            >Matriz de Cambios</b-card-title
          >
          <b-card-sub-title></b-card-sub-title>
        </div>
  
        <!-- Card Actions -->
        <div class="heading-elements">
          <ul class="list-inline mb-0">
            <li>
              <b-button
                variant="flat-secondary"
                class="btn-icon"
                @click="refresh"
              >
                <feather-icon icon="RefreshCwIcon" size="20" />
              </b-button>
            </li>
            <li>
              <feather-icon icon="HelpCircleIcon" v-b-toggle.sidebar-2 size="20" />
            </li>
          </ul>
        </div>
      </div>
      <div>
        <b-sidebar id="sidebar-2" title="Matriz Cambio Formato" shadow bg-variant="dark" text-variant="light" width="450px"
        right backdrop>
          <div class="px-2 py-2">
              <p>Esta vista contempla el tiempo y los costos asociados a cada cambio de formato, segmentado por planta.</p>
          </div>
        </b-sidebar>
      </div>
  
      <!-- Card Body  -->
      <b-card-body>
        <ag-grid-vue
          style="width: 100%; height: 500px"
          class="ag-theme-alpine"
          :columnDefs="columnDefs"
          @grid-ready="onGridReady"
          @first-data-rendered="firstDataRendered"
          :defaultColDef="defaultColDef"
          :autoGroupColumnDef="autoGroupColumnDef"
          :groupDefaultExpanded="groupDefaultExpanded"
          @cellValueChanged="onCellValueChanged"
          :rowData="rowData"
        ></ag-grid-vue>
      </b-card-body>
    </b-card>
  </template>
  
  <script>
  
  
  import
  {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormCheckbox,
    BFormGroup,
    BFormCheckboxGroup,
    BAvatar,
    VBPopover,
    BButton
  } from "bootstrap-vue";
  
  import { AgGridVue } from "ag-grid-vue";
  
  import axios from '@axios'
  
  export default {
  
    directives: {
      "b-popover": VBPopover,
    },
  
    props: {
  
      dataUrl: {
        type: String,
        required: true,
      },
  
      editable: {
        type: Boolean,
        required: false,
        default: true
      },
  
  
    },
  
    data()
    {
      console.log("Editable: ", this.editable);
  
      return {
  
        columnDefs: [
  
        ],
        gridApi: null,
        columnApi: null,
        defaultColDef: {
          editable: this.editable,
          sortable: true,
          filter: true,
          resizable: true,
          valueParser: params => {
  
            let newValue = Number(params.newValue)
  
            if(isNaN(newValue)){
              return Number(params.oldValue)
            }
  
            return newValue
  
  
          } 
  
        },
        autoGroupColumnDef: null,
        groupDefaultExpanded: null,
        rowData: null,
  
  
  
  
      };
    },
  
  
    components: {
      BRow,
      BCol,
      BCard,
      BCardBody,
      BFormCheckbox,
      BFormGroup,
      BFormCheckboxGroup,
      BCardTitle,
      BCardSubTitle,
      BAvatar,
      BButton,
  
      AgGridVue,
      VBPopover
  
    },
  
    created()
    {
      this.updateData()
    },
  
  
    methods: {
  
      updateData()
      {
        this.getData()
          .then((response) =>
          {
            console.log({ response })
  
            this.columnDefs = response.data.columnDefs;
            this.rowData = response.data.rowData;
  
          })
  
  
      },
  
      refresh()
      {
  
        this.updateData()
  
      },
  
  
      firstDataRendered(params)
      {
  
        console.log("firstDataRendered")
  
        //this.gridColumnApi.autoSizeAllColumns();
  
      },
  
      onCellValueChanged(params)
      {
  
        console.log("onCellValueChanged");
        console.log(params);
  
        console.log(this.rowData);
  
        this.$emit('change', this.gridApi, this.gridColumnApi, params);
      },
  
      onGridReady(params)
      {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
  
        console.log("onGridReady")
  
        //const updateData = (data) => params.api.setRowData(data.slice(0, 50));
  
      },
  
  
      getData()
      {
        return axios.request({
          method: "get",
          url: this.dataUrl,
          headers: {
            Accept: "application/json",
          },
        })
      },
  
  
  
  
    },
  };
  </script>
  
  <style scoped>
  .titulo {
    margin-top: 5%;
  }
  </style>
  
  
  <style lang="scss" scoped>
  @import "~ag-grid-community/dist/styles/ag-grid.css";
  @import "@core/scss/vue/pages/ui-feather.scss";
  
  //@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
  @import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
  
  div.ag-watermark {
    display: none !important;
  }
  
  .ag-pivot-off .ag-header-group-cell {
    font-size: 25px;
    color: #8a6d3b;
  }
  
  .ag-pivot-on .ag-header-group-cell {
    font-size: 25px;
    color: #8a6d3b;
  }
  
  .ag-cell-label-container {
    /* Necessary to allow for text to grow vertically */
    height: 100%;
  }
  
  .ag-header-cell-label {
    /* Necessary to allow for text to grow vertically */
    height: 100%;
    padding: 0 !important;
  }
  
  .ag-header-cell-label .ag-header-cell-text {
    /* Force the width corresponding at how much width
      we need once the text is laid out vertically */
    //width: 30px;
    //transform: rotate(90deg);
    //margin-top: 50px;
    /* Since we are rotating a span */
    display: inline-block;
  }
  
  .ag-floating-filter-body input {
    height: 49px;
  }
  
  .ag-floating-filter-button {
    margin-top: -49px;
  }
  
  .ag-floating-filter-button button {
    height: 49px;
  }
  
  .ag-floating-filter-body input {
    font-size: 15px;
    font-weight: bold;
  }
  
  .ag-pivot-off .ag-header-cell-label {
    color: #8a6d3b;
  }
  
  .ag-pivot-on .ag-header-cell-label {
    color: #1b6d85;
    font-weight: bold;
  }
  </style>
  
  <style lang="scss" scoped>
  .card {
    .card-title {
      margin-bottom: 1.5rem !important;
    }
  
    ::v-deep .card-header {
      .heading-elements {
        position: static;
        cursor: inherit;
  
        .list-inline {
          display: block;
  
          li {
            a {
              padding: 0;
            }
  
            &:not(:last-child) {
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
  </style>
  
  