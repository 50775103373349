<template>
    <section>
      <b-tabs content-class="mt-3">
        <b-tab title="Planta 1">
            <matriz-cambios-planta-table ref="matrizCambiosPlantaP1Ref" :dataUrl="matrizCambiosP1"></matriz-cambios-planta-table>
        </b-tab>
        <b-tab title="Planta 2">
            <matriz-cambios-planta-table ref="matrizCambiosPlantaP2Ref" :dataUrl="matrizCambiosP2"></matriz-cambios-planta-table>
        </b-tab>
        <b-tab title="Planta 3">
            <matriz-cambios-planta-table ref="matrizCambiosPlantaP3Ref" :dataUrl="matrizCambiosP3"> </matriz-cambios-planta-table>
        </b-tab>
      </b-tabs>
  
    </section>
  </template>
    <script>
  import
  {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BIconNodeMinusFill,
    BTabs,
    BSpinner,
    BAlert,
    BTab,
    BLink,
    VBPopover,
    VBTooltip,
    BFormGroup,
    BFormInvalidFeedback
  
  } from "bootstrap-vue";
  
  
  
  
  import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";
  
  import useApiServices from '@/services/useApiServices.js';
  import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
  
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
  import MatrizCambiosPlantaTable from "./MatrizCambiosPlantaTable.vue";

  import axios from '@axios'
  
  export default {
  
    created()
    {
  
  
  
    },
  
  
    data()
    {
  
  
  
      return {


        matrizCambiosP1: useApiServices.settingsMatrizCambiosTable + '?planta=Planta 1',
        matrizCambiosP2: useApiServices.settingsMatrizCambiosTable + '?planta=Planta 2',
        matrizCambiosP3: useApiServices.settingsMatrizCambiosTable + '?planta=Planta 3',
  
        phasingEntregaTable: useApiServices.settingsPhasingEntregaTable,
  
  
  
  
  
  
  
      };
    },
    components: {
      BRow,
      BCol,
      BCard,
      BButton,
      BCardHeader,
      BFormFile,
      BCardTitle,
      BCardSubTitle,
      BCardText,
      BCardBody,
      BFormSelect,
      BTabs,
      VBPopover,
      VBTooltip,
      BTab,
      BLink,
      BAlert,
      BSpinner,
      //GoodTableBasic,
      BFormInvalidFeedback,
      BFormGroup,
      BrunaForm,
      AgGridTable,
      ToastificationContent,
      MatrizCambiosPlantaTable
    },
  
    directives: {
      'b-tooltip': VBTooltip,
      "b-popover": VBPopover,
    },
  
    props: {
  
      disabled: {
        type: Boolean,
        required: false,
        default: false
      },
  
  
    },
  
    mounted()
    {
      this.getLastSetting()
        .then((response) =>
        {
          if (response.data)
          {
            let metadata = response.data.meta_data
  
            /*
            let ajustes_velocidad = metadata.ajustes_velocidad
  
            for (let property in ajustes_velocidad)
            {
              let field = this.findFieldByName(this.ajustes_velocidad.fields, property)
  
              field.value = ajustes_velocidad[property]
            }
  */
  
  
  
          }
  
          console.log(response)
  
        })
  
    },
  
  
   
  
    methods: {


      getData()
      {
        return {
          "Planta 1" : this.$refs.matrizCambiosPlantaP1Ref.rowData,
          "Planta 2" : this.$refs.matrizCambiosPlantaP2Ref.rowData,
          "Planta 3" : this.$refs.matrizCambiosPlantaP3Ref.rowData,
        }
      },
  
  
      findFieldByName(fields, name)
      {
  
        return fields.find(field => field.id == name)
  
      },
  
  
      getLastSetting()
      {
        return axios.request({
          method: "get",
          url: useApiServices.settingsLast,
          headers: {
            Accept: "application/json",
          },
        })
      },
  

   

  
  
    },
  };
    </script>
    
    <style scoped>
  .carga-data {
    margin-top: 4%;
  }
  </style>
    
    