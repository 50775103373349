<template>
  <section>
    <b-tabs content-class="mt-3">
      <b-tab title="Generales">
        <!--
        <BrunaForm
          ref="ajustesVelocidadRef"
          v-bind:fields="this.ajustes_velocidad.fields"
          :card="this.ajustes_velocidad.card"
          @change="ajustesVelocidadChange"
        ></BrunaForm>
-->
        <phasing-entrega-table
          ref="phasingEntregaRef"
          :dataUrl="phasingEntregaTable"
          :editable="!disabled"
          @change="phasingEntregaChange"
        ></phasing-entrega-table>
      </b-tab>
      <b-tab title="Matriz de Cambios">
        <matriz-cambios ref="matrizCambiosRef" ></matriz-cambios>
      </b-tab>
    </b-tabs>

    <b-card v-if="!disabled">
      <b-card-body>
        <b-button
          variant="primary"
          class="mt-4 float-right"
          @click="save"
          size="lg"
          >Guardar</b-button
        >
      </b-card-body>
    </b-card>
  </section>
</template>
  <script>
import
{
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";




import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";

import useApiServices from '@/services/useApiServices.js';
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import PhasingEntregaTable from "./PhasingEntregaTable.vue";

import MatrizCambios from './MatrizCambios.vue'

import axios from '@axios'

export default {

  created()
  {



  },


  data()
  {



    return {

      unsavedChanges: false,


      ajustes_velocidad: {
        card: {
          title: "Ajustes de Velocidad",
          subtitle: "",
          tooltip: ""
        },
        fields: [
          {
            label: "Ajustes Velocidad Planta 1 Primera Calidad %",
            type: "input",
            id: 'ajustes_velocidad_planta_1_primera_calidad_percent',
            rules: 'required|numeric2',
            tooltip: null,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
          },
          {
            label: "Ajustes Velocidad Planta 2 Primera Calidad %",
            type: "input",
            id: 'ajustes_velocidad_planta_2_primera_calidad_percent',
            rules: 'required|numeric2',
            tooltip: null,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
          },
          {
            label: "Ajustes Velocidad Planta 3 Primera Calidad %",
            type: "input",
            id: 'ajustes_velocidad_planta_3_primera_calidad_percent',
            rules: 'required|numeric2',
            tooltip: null,
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null,
          },
        ]
      },


      phasingEntregaTable: useApiServices.settingsPhasingEntregaTable,







    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    //GoodTableBasic,
    BFormInvalidFeedback,
    BFormGroup,
    BrunaForm,
    AgGridTable,
    PhasingEntregaTable,
    ToastificationContent,
    MatrizCambios
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  props: {

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },


  },

  mounted()
  {
    this.getLastSetting()
      .then((response) =>
      {
        if (response.data)
        {
          let metadata = response.data.meta_data

          let ajustes_velocidad = metadata.ajustes_velocidad

          for (let property in ajustes_velocidad)
          {
            let field = this.findFieldByName(this.ajustes_velocidad.fields, property)

            field.value = ajustes_velocidad[property]
          }




        }

        console.log(response)

      })

  },


  beforeRouteLeave(to, from, next)
  {




    if (this.unsavedChanges)
    {
      this.$bvModal
        .msgBoxConfirm('Tus cambios no se han guardado. ¿Descartar cambios?', {
          title: 'Confirmar descartar cambios',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Aceptar',
          cancelTitle: 'Cancelar',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value =>
        {
          if (value)
          {
            next()
          }
          else
          {
            next(false)
          }

        })

    }
    else
    {
      next()
    }

  },


  methods: {


    findFieldByName(fields, name)
    {

      return fields.find(field => field.id == name)

    },


    getLastSetting()
    {
      return axios.request({
        method: "get",
        url: useApiServices.settingsLast,
        headers: {
          Accept: "application/json",
        },
      })
    },


    formatData()
    {

      return {
        ajustes_velocidad: this.ajustes_velocidad.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        phasing_entrega: this.$refs.phasingEntregaRef.rowData,
        matriz_cambios: this.$refs.matrizCambiosRef.getData()
      }


    },

    postData()
    {

      useApiServices.updateLastSetting(this.formatData())
        .then((response) =>
        {
          this.unsavedChanges = false;

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Carga Exitosa`,
              icon: "UploadIcon",
              variant: "success",
              html: `Los ajustes se han guardado exitosamente.`,
            },
          });


        })
        .catch((error) =>
        {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ocurrio un Error`,
              icon: "UploadIcon",
              variant: "danger",
              html: `Ha ocurrido un error al guardar los ajustes</b>.`,
            },
          });

        });





    },

    save()
    {

      /*
      let self = this;

      Promise.all([
        this.$refs.ajustesVelocidadRef.validate(),


      ]).then(([ajustesVelocidadValid]) =>
      {
        console.log(self.formatData())

        if (ajustesVelocidadValid)
        {
          this.postData()
        }




      })
      */

      this.postData()


    },

    ajustesVelocidadChange()
    {
      console.log("capacidadCosechaChange");
      this.unsavedChanges = true;

    },

    phasingEntregaChange()
    {
      console.log("phasingEntregaChange");
      this.unsavedChanges = true;
    },




  },
};
  </script>
  
  <style scoped>
.carga-data {
  margin-top: 4%;
}
</style>
  
  